<template>
    <SectionLayout>
        <v-col cols="12" class="ma-0 pa-0">
        <v-row justify="center"  class="py-5">
            <v-col style="text-align: center">
                 <h1 class="display-2 font-weight-light">Thank you</h1>
            </v-col>
        </v-row>
        <v-row justify="center"  class="py-5 mt-5" v-if="status === 'paid'">
            <v-col style="text-align: center">
                 <p class="headline">We received the payment.</p>
            </v-col>
        </v-row>
        <v-row justify="center"  class="py-5 mt-5" v-if="status !== 'paid'">
            <v-col style="text-align: center">
                 <p class="headline">We have not received the payment yet.</p>
            </v-col>
        </v-row>
        <v-row justify="center"  class="py-5">
            <v-col style="text-align: center">
                <!-- TODO: for vpn service, mention specifically that to get the vpn connection details, they need to continue to customer service website, and also if they are a new user, they need to create an account there using the SAME email they provided when they made the purchase -->
                 <p class="headline">Continue to our <a href="https://customer.cryptium.com/">customer service</a> site</p>
            </v-col>
        </v-row>
        <!-- TODO: link to invoice? or they have to login to customer service site to see that? -->
        </v-col>
    </SectionLayout>
</template>

<style scoped>
p {
    color: #777;
}
p a {
    color: #0080ff;
}
</style>

<script>
import SectionLayout from '@/components/SectionLayout.vue';
import { Client } from '@/client';

const client = new Client();

export default {
    components: {
        SectionLayout,
    },
    data() {
        return {
            interactionId: null,
            items: [],
            incidentId: null,
            invalidRequest: false,
            unknownError: false,
            status: null, // 'paid' or 'unpaid' after we check stripe
        };
    },
    methods: {
        async loadPaymentInteraction(interactionId) {
            try {
                console.log(`loadPaymentInteraction: interactionId ${interactionId}`);
                const response = await client.payment.getPaymentInteractionById(interactionId);
                console.log(`loadPaymentInteraction: response ${JSON.stringify(response)}`);
                this.interactionId = response.id;
                this.items.push(...response.items);
                this.status = response.status;
                this.loading = false;
                // TODO: if item not found, redirect to /error?type=unknown-item&incidentId=<incidentId>  (with an incident id we got from server, so we can forward it in query parameter, and customer support will be able to find the item id or other information in the server log when they search for this incidentId)
            } catch (err) {
                console.error('loadPaymentInteraction failed', err);
            }
        },
    },
    mounted() {
        const {
            i: interactionId,
        } = this.$route.query;
        console.log(`mounted: interactionId ${interactionId}`);
        if (interactionId) {
            this.loadPaymentInteraction(interactionId);
        } else {
            this.$router.replace('/error?type=invalid-request');
        }
    },
};
</script>
